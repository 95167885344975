import request from '@/utils/request';

export function sendToConsumer(data) {
  return request({
    url: '/v1/pushNotification/sendToConsumer',
    method: 'post',
    data,
  });
}

export function sendAllUnderCaptain(data) {
  return request({
    url: '/v1/pushNotification/sendAllUnderCaptain',
    method: 'post',
    data,
  });
}

export function sendAll(data) {
  return request({
    url: '/v1/pushNotification/sendAllUserNotification',
    method: 'post',
    data,
  });
}

export function sendAllByOngoingProduct(data) {
  return request({
    url: '/v1/pushNotification/sendAllByOngoingProduct',
    method: 'post',
    data,
  });
}

export function sendAllByBulk(data) {
  return request({
    url: '/v1/pushNotification/sendAllByBulk',
    method: 'post',
    data,
  });
}
