export const ConvertToBulkStatus = '_BULK_STATUS';
export const OrderStatusDict = {
  CREATED: {
    BULKING: {
      display() {
        return 'Created';
      },
      option() {
        return null;
      },
    },
    DIRECT_PURCHASE: {
      display() {
        return 'Created';
      },
      option() {
        return null;
      },
    },
    DIGITAL_BULKING: {
      display() {
        return 'Created';
      },
      option() {
        return null;
      },
    },
  },
  PREPARING: {
    BULKING: {
      display(bulkStatus) {
        if (bulkStatus == 'In progress') return 'Unlocked';
        if (bulkStatus == 'Success') return 'Enabled';
        return 'Invalid';
      },
      option() {
        return null;
      },
    },
    DIRECT_PURCHASE: {
      display() {
        return 'Preparing';
      },
      option() {
        return null;
      },
    },
    DIGITAL_BULKING: {
      display(bulkStatus) {
        if (bulkStatus == 'In progress') return 'Boost';
        if (bulkStatus == 'Success') return 'Enabled';
        return 'Invalid';
      },
      option() {
        return null;
      },
    },

  },
  DELIVERING: {
    BULKING: {
      display() {
        return 'Delivering';
      },
      option() {
        return null;
      },
    },
    DIRECT_PURCHASE: {
      display() {
        return 'Delivering';
      },
      option() {
        return {
          name: 'Delivering',
          value: 'DELIVERING',
        };
      },
    },
    DIGITAL_BULKING: {
      display() {
        return 'Ready To Use';
      },
      option() {
        return null;
      },
    },
  },
  DELIVERED: {
    BULKING: {
      display() {
        return 'Completed';
      },
      option() {
        return {
          name: 'Completed',
          value: 'DELIVERED',
        };
      },
    },
    DIRECT_PURCHASE: {
      display() {
        return 'Completed';
      },
      option() {
        return {
          name: 'Completed',
          value: 'DELIVERED',
        };
      },
    },
    DIGITAL_BULKING: {
      display() {
        return 'Used';
      },
      option() {
        return null;
      },
    },
  },
  RECEIVED: {
    BULKING: {
      display() {
        return 'Completed';
      },
      option() {
        return null;
      },
    },
    DIRECT_PURCHASE: {
      display() {
        return 'Completed';
      },
      option() {
        return null;
      },
    },
    DIGITAL_BULKING: {
      display() {
        return 'Completed(Received)';
      },
      option() {
        return null;
      },
    },
  },
  FAILED: {
    BULKING: {
      display() {
        return 'Failed';
      },
      option() {
        return null;
      },
    },
    DIRECT_PURCHASE: {
      display() {
        return 'Failed';
      },
      option() {
        return null;
      },
    },
    DIGITAL_BULKING: {
      display() {
        return 'Failed';
      },
      option() {
        return null;
      },
    },
  },
  REFUNDED: {
    BULKING: {
      display() {
        return 'Refunded';
      },
      option() {
        return null;
      },
    },
    DIRECT_PURCHASE: {
      display() {
        return 'Refunded';
      },
      option() {
        return null;
      },
    },
    DIGITAL_BULKING: {
      display() {
        return 'Refunded';
      },
      option() {
        return null;
      },
    },
  },
  DISPUTED: {
    BULKING: {
      display() {
        return 'Disputed';
      },
      option() {
        return {
          name: 'Disputed',
          value: 'DISPUTED',
        };
      },
    },
    DIRECT_PURCHASE: {
      display() {
        return 'Disputed';
      },
      option() {
        return {
          name: 'Disputed',
          value: 'DISPUTED',
        };
      },
    },
    DIGITAL_BULKING: {
      display() {
        return 'Disputed';
      },
      option() {
        return null;
      },
    },
  },
  TERMINATED: {
    BULKING: {
      display() {
        return 'Terminated';
      },
      option() {
        return {
          name: 'Terminated',
          value: 'TERMINATED',
        };
      },
    },
    DIRECT_PURCHASE: {
      display() {
        return 'Terminated';
      },
      option() {
        return {
          name: 'Terminated',
          value: 'TERMINATED',
        };
      },
    },
    DIGITAL_BULKING: {
      display() {
        return 'Terminated';
      },
      option() {
        return {
          name: 'Terminated',
          value: 'TERMINATED',
        };
      },
    },
  },
};
